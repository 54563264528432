.main-content{
    display: flex;
    justify-content: center;
}

.second-content{
    display: flex;
    justify-content: flex-start;
    margin: 3rem;
    flex-direction: column;
    width: 21rem;
}

.main-title{
    font-size: 1.5rem;
    font-weight: bold;
}

.autocomplete-content{
    margin-top: 2rem;
}

.autocomplete-content-matter{
    margin-top: 1.5rem;
}

.label-form{
    font-size: 1rem !important;
}

.autocomplete-size {
    width: 21rem;
    margin-top: 0.5rem;
}

.textfiel-autocomplete .MuiOutlinedInput-root {
    height: 40px;
}

.textfiel-autocomplete .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline{
    border-color: #8f8f9d !important;
}

.textfiel-autocomplete .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #525257 !important;
}
  
.textfiel-autocomplete .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #0d6efd !important;
}

.textfiel-autocomplete .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-color: #c7c7ce !important;
  }

.content-textarea{
    display: flex;
    flex-direction: column;
    margin-top: 1.4rem;
}

.size-textare{
    margin-top: 0.5rem;
    height: 10rem;
    max-width: 20.5rem !important;
    font-size: 0.9rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.button-save{
    background-color: #007FFF !important;
    font-weight: 600 !important;
    font-size: 0.875rem !important;
    line-height: 1.5 !important; 
    color: white !important;
}

.btn-save{
    text-transform: none !important;
    width: 21rem;
}

.content-btn{
    display: flex;
    margin-top: 1rem;
}

.btn-save:disabled{
    background-color: #1976d2 !important;
    opacity: 0.5 !important;
    color: white !important;
}