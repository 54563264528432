.main-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.img-content{
    margin-top: 3rem;
}

.content-info{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 27rem;
    margin-top: -2rem;
}

.error-title{
    font-size: 2rem;
    font-weight: bold;
}

.error-info{
    text-align: center;
    margin-top: 1rem;
    font-size: 1.1rem;
}